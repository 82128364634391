import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingRsppPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Rspp'

  const description =
    'La formazione minima per ricoprire il ruolo di RSPP si compone dei seguenti moduli:<br><br> <b>• Modulo A</b> – <b>effettuabile anche in e-Learning</b> (corso propedeutico comune per RSPP e ASSP che prevede la frequenza di 28 ore di formazione in aula valido per qualsiasi macro-settore di attività lavorativa);<br> <b>• Modulo B comune</b> – effettuabile solo in presenza (corso di specializzazione specifico della durata totale di 48 ore, sia per RSPP sia per ASPP); per coloro che vogliono ricoprire l’incarico di RSPP in specifici settori è necessario frequentare, oltre al modulo B comune, anche dei <b>moduli di specializzazione</b>: SP1 – agricoltura e Pesca 12 ore; SP2 – cave e costruzioni 16 ore; SP3 Sanità residenzaiale 12 ore; SP4 chimico-petrolchimico 16 ore.<br> <b>• Modulo C</b> (solo per RSPP) effettuabile solo in presenza (corso di specializzazione specifico sui temi della comunicazione della durata minima di 24 ore).<br><br> L’accordo Stato-Regioni del luglio 2016 definisce le tipologie di <b>corsi di formazione di aggiornamento</b> per i responsabili dei servizi di prevenzione e protezione sui luoghi di lavoro. L’aggiornamento consiste nella partecipazione a un <b>corso di 40 ore per RSPP</b> da effettuarsi con periodicità quinquennale <b>che può essere effettuato anche in modalità e-learning.</b><br><br> ING GROUP organizza tutti i suddetti corsi per RSPP sia in aula che in modalità e-learning (quando previsto dalla legge).'

  const description1 = null

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        description1={description1}
      />
    </>
  )
}
